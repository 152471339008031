import React, { Fragment, useState } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { LuChevronsUpDown } from "react-icons/lu";
import { MdDone } from "react-icons/md";

export default function AutoCompleteCustomer({
  jobNewData,
  handleInputChangeNewData,
  option,
  color
}) {

  const [selected, setSelected] = useState(
    jobNewData !== ""
      ? option?.filter((i) => i?.id === jobNewData)[0]
      : "Select Customer"
  );
  const [query, setQuery] = useState("");

  const filteredOption =
    query === ""
      ? option
      : option.filter((person) =>
          person.firstName
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  const onPress = (item) => {
    handleInputChangeNewData(item);
  };

  return (
    <div>
      <Combobox
        value={selected}
        onChange={(e) => {
          setSelected(e);
          onPress(e);
        }}
      >
        <div className="relative mt-1">
          <div
            className="relative w-full cursor-default overflow-hidden rounded-lg
           bg-white text-left shadow-md focus:outline-none focus-visible:ring-2
            focus-visible:ring-white/75 focus-visible:ring-offset-2 
            focus-visible:ring-offset-teal-300 sm:text-sm"
          >
            <Combobox.Input
              className={`w-full border-none py-2 pl-3 pr-10 text-sm leading-5
               focus:ring-0 outline-0
                ${
                  color
                    ? "bg-blue-600 text-white placeholder:text-white"
                    : "text-gray-900 placeholder:text-gray-900"
                }`}
              placeholder="Select Customer"
              displayValue={(person) =>
                selected === "Select Customer"
                  ? ""
                  : person.firstName + " " + person.lastName
              }
              onChange={(event) => {
                setQuery(event.target.value);
              }}
            />
            <Combobox.Button
              className={`absolute inset-y-0 right-0 flex items-center pr-2
                  ${color ? "bg-blue-600 text-white" : "text-gray-900"}`}
            >
              <LuChevronsUpDown />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery("")}
          >
            <Combobox.Options
              className="absolute mt-1 z-10 max-h-60 w-full overflow-auto rounded-md 
            bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
            >
              {filteredOption.length === 0 && query !== "" ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredOption.map((person) => (
                  <Combobox.Option
                    key={person.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? "bg-teal-600 text-white" : "text-gray-900"
                      }`
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {person?.firstName + " " + person?.lastName}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                              active ? "text-white" : "text-teal-600"
                            }`}
                          >
                            <MdDone />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
}
