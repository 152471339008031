const validate = (values) => {
    const errors = {};
  
    /* validating email using regex to pass email */
    if (!values.email) {
      errors.email = (
        <div className="text-red-500 font-medium text-left pl-1">
          Email address is required
        </div>
      );
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = (
        <div className="text-red-500 font-medium text-left pl-1">
          Invalid email address
        </div>
      );
    }
  
    /* validating passwords */
    if (!values.password) {
      errors.password = (
        <div className="text-red-500 font-medium text-left pl-1">
          Password is required
        </div>
      );
    }
  
    return errors;
  };
  
  export default validate;