import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { MdAdd, MdEdit } from "react-icons/md";
import {
  getNurseAvaJobDate,
  getNurseDetails,
  getNurseId,
  getSwapNurseAvailableList
} from "../../api_url/nurseTable";
import NurseData from "./NurseData";
import { Dialog, Transition } from "@headlessui/react";
import UserJobCard from "./UserJobCard";
import MultiSelect from "./MultiSelect";
import NurseForm from "../user/NurseForm";

const Day = ({
  index,
  shiftData,
  authToken,
  orgLocation,
  orgLocationIdValue,
  fullAddress,
  latitude,
  longitude,
  customerName,
  customerId,
  handleUpdate,
  nurseData,
  setNurseData,
  date18YearsAgo,
  handleInputChangeNewData,
  postNurse
}) => {
  const removeTitles = (name) => {
    return name?.replace(/( RN| CNA| LPN| HHA)$/i, "");
  };

  // function timeDifferent(sTime, eTime) {
  //   const startTime = moment(
  //     moment(new Date(sTime)).format("hh:mma"),
  //     "hh:mm A"
  //   );

  //   const start = moment(new Date(sTime));
  //   const end = moment(new Date(eTime));

  //   const endTime = moment(moment(new Date(eTime)).format("hh:mma"), "hh:mm A");
  //   const endTimeEnd = moment(
  //     moment(new Date(eTime)).format("hh:mma"),
  //     "hh:mm A"
  //   ).add(1, "day");

  //   const duration = moment.duration(endTime.diff(startTime));
  //   const durationEnd = moment.duration(endTimeEnd.diff(startTime));

  //   const hours = Math.floor(duration.asHours());
  //   const hoursEnd = Math.floor(durationEnd.asHours());
  //   const minutes = Math.floor(duration.asMinutes()) - hours * 60;

  //   const onlyHours = `${
  //     end.hour() <= 12 && start.hour() <= 12
  //       ? hours
  //       : end.hour() <= 12
  //       ? hoursEnd
  //       : hours
  //   }${hours <= 1 ? "hour" : "hours"} ${
  //     minutes <= 0 ? "" : `${minutes}minutes`
  //   }`;

  //   return onlyHours;
  // }

  const [startTime, endTime] = shiftData.shift_1.time
    ?.split("-")
    .map((time) => time.trim());
  const [startTime2, endTime2] = shiftData.shift_2.time
    ?.split("-")
    .map((time) => time.trim());

  const getLicenseType = (name) => {
    if (name?.toLocaleLowerCase() === "registered nurse") {
      return "RN";
    } else if (name?.toLocaleLowerCase() === "home health aide") {
      return "HHA";
    } else if (name?.toLocaleLowerCase() === "licensed practical nurse") {
      return "LPN";
    } else {
      return;
    }
  };

  const getLicenseTypeView = (name) => {
    if (name?.toLocaleLowerCase() === "registered nurse") {
      return "RN";
    } else if (name?.toLocaleLowerCase() === "home health aide") {
      return "HHA";
    } else if (name?.toLocaleLowerCase() === "licensed practical nurse") {
      return "LPN";
    } else {
      let li = name.split("-");
      return li[0];
    }
  };

  const [loadingShift1, setLoadingShift1] = useState(true);
  const [loadingShift2, setLoadingShift2] = useState(true);
  const [loadingNurseList, setLoadingNurseList] = useState(false);

  const [nurseList, setNurseList] = useState(undefined);
  const [addRecord, setAddRecord] = useState(false);
  const [address, setAddress] = useState(false);
  const [addNurse, setAddNurse] = useState(false);
  const [addNurseType, setAddNurseType] = useState("");
  const [addNurseTypeButton, setAddNurseTypeButton] = useState("");
  const [addNurseLicenseType, setAddNurseLicenseType] = useState([]);
  const [type, setType] = useState("Shift");
  const [searchTerm, setSearchTerm] = useState("");

  const [jobNewData, setJobNewData] = useState({
    shiftTitle: customerName + " " + shiftData.shift_1.name + "-Job Title",
    startDate: moment(new Date(shiftData.fullDate)).format("YYYY-MM-DD"),
    endDate: moment(new Date(shiftData.fullDate)).format("YYYY-MM-DD"),
    startTime: startTime,
    endTime: endTime,
    break: "No Break",
    customerName: customerName,
    customerId: customerId,
    fullAddress: fullAddress,
    latitude: latitude,
    longitude: longitude,
    jobType: type,
    customerVisibility: true,
    jobPostingTableFacilityTableId: "",
    jobPostingTableFacilityTableName: "",
    licenseTypeArr: [getLicenseType(shiftData.shift_1.role)],
    jobFinalNurseTableId: "",
    jobFinalNurseTable: undefined
  });

  const [jobNewData2, setJobNewData2] = useState({
    shiftTitle: customerName + " " + shiftData.shift_2.name + "-Job Title",
    startDate: moment(new Date(shiftData.fullDate)).format("YYYY-MM-DD"),
    endDate: moment(new Date(shiftData.fullDate)).format("YYYY-MM-DD"),
    startTime: startTime2,
    endTime: endTime2,
    break: "No Break",
    customerName: customerName,
    customerId: customerId,
    fullAddress: fullAddress,
    latitude: latitude,
    longitude: longitude,
    jobType: type,
    customerVisibility: true,
    jobPostingTableFacilityTableId: "",
    jobPostingTableFacilityTableName: "",
    licenseTypeArr: [getLicenseType(shiftData.shift_2.role)],
    jobFinalNurseTableId: "",
    jobFinalNurseTable: undefined
  });

  const getNurse = async (
    firstName,
    lastName,
    startDate,
    endDate,
    startTime,
    endTime
  ) => {
    try {
      const response = await getNurseId(authToken, firstName, lastName);
      const res = await getNurseAvaJobDate(
        authToken,
        response?.id,
        startDate,
        endDate,
        startTime,
        endTime
      );

      let data = {
        ...response,
        ...res
      };
      return data ? data : "";
    } catch (error) {
      return null;
    }
  };

  const getNurseWithId = async (id, startDate, endDate, startTime, endTime) => {
    try {
      const response = await getNurseDetails(authToken, id);
      const res = await getNurseAvaJobDate(
        authToken,
        response?.id,
        startDate,
        endDate,
        startTime,
        endTime
      );

      let data = {
        ...response,
        ...res
      };
      return data ? data : "";
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    if (shiftData.shift_1.name) {
      let nameObject = removeTitles(shiftData.shift_1.name)?.split(" ");
      if (nameObject?.length <= 2) {
        getNurse(
          nameObject[0],
          nameObject[1],
          moment(new Date(shiftData.fullDate)).format("YYYY-MM-DD"),
          moment(new Date(shiftData.fullDate)).format("YYYY-MM-DD"),
          startTime,
          endTime
        ).then((res) => {
          if (res) {
            let data = {
              index,
              name: "shift_1",
              value: {
                ...shiftData.shift_1,
                jobFinalNurseTableId: res?.id,
                jobFinalNurseTable: res
              }
            };
            handleUpdate(data);
            setJobNewData({
              ...jobNewData,
              jobFinalNurseTableId: res?.id,
              jobFinalNurseTable: res
            });
            setLoadingShift1(false);
          } else {
            setLoadingShift1(false);
          }
        });
      } else if (nameObject?.length <= 3) {
        getNurse(
          nameObject[0] + " " + nameObject[1],
          nameObject[2],
          moment(new Date(shiftData.fullDate)).format("YYYY-MM-DD"),
          moment(new Date(shiftData.fullDate)).format("YYYY-MM-DD"),
          startTime,
          endTime
        ).then((res) => {
          if (res) {
            let data = {
              index,
              name: "shift_1",
              value: {
                ...shiftData.shift_1,
                jobFinalNurseTableId: res?.id,
                jobFinalNurseTable: res
              }
            };
            handleUpdate(data);
            setJobNewData({
              ...jobNewData,
              jobFinalNurseTableId: res?.id,
              jobFinalNurseTable: res
            });
            setLoadingShift1(false);
          } else {
            setLoadingShift1(false);
          }
        });
      } else {
        setLoadingShift1(false);
      }
    } else {
      setLoadingShift1(false);
    }

    if (shiftData.shift_2.name) {
      let nameObject = removeTitles(shiftData.shift_2.name)?.split(" ");
      if (nameObject?.length <= 2) {
        getNurse(
          nameObject[0],
          nameObject[1],
          moment(new Date(shiftData.fullDate)).format("YYYY-MM-DD"),
          moment(new Date(shiftData.fullDate)).format("YYYY-MM-DD"),
          startTime2,
          endTime2
        ).then((res) => {
          if (res) {
            let data = {
              index,
              name: "shift_2",
              value: {
                ...shiftData.shift_2,
                jobFinalNurseTableId: res?.id,
                jobFinalNurseTable: res
              }
            };
            handleUpdate(data);
            setJobNewData2({
              ...jobNewData2,
              jobFinalNurseTableId: res?.id,
              jobFinalNurseTable: res
            });
            setLoadingShift2(false);
          } else {
            setLoadingShift2(false);
          }
        });
      } else if (nameObject?.length <= 3) {
        getNurse(
          nameObject[0] + " " + nameObject[1],
          nameObject[2],
          moment(new Date(shiftData.fullDate)).format("YYYY-MM-DD"),
          moment(new Date(shiftData.fullDate)).format("YYYY-MM-DD"),
          startTime2,
          endTime2
        ).then((res) => {
          if (res) {
            let data = {
              index,
              name: "shift_2",
              value: {
                ...shiftData.shift_2,
                jobFinalNurseTableId: res?.id,
                jobFinalNurseTable: res
              }
            };
            handleUpdate(data);
            setJobNewData2({
              ...jobNewData2,
              jobFinalNurseTableId: res?.id,
              jobFinalNurseTable: res
            });
            setLoadingShift2(false);
          } else {
            setLoadingShift2(false);
          }
        });
      } else {
        setLoadingShift1(false);
      }
    } else {
      setLoadingShift2(false);
    }
  }, [shiftData]);

  const getNurseList = async (
    org,
    location,
    startDate,
    endDate,
    startTime,
    endTime,
    licenseType,
    fullAddress,
    from
  ) => {
    setLoadingNurseList(true);
    try {
      const response = await getSwapNurseAvailableList(
        authToken,
        org,
        location,
        startDate,
        endDate,
        startTime,
        endTime,
        licenseType,
        fullAddress
      );
      setNurseList(response);
      setLoadingNurseList(false);
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    if (addNurse && addNurseType === "1") {
      if (jobNewData?.jobFinalNurseTable?.availableDate) {
        getNurseList(
          orgLocation,
          orgLocationIdValue,
          moment(new Date(shiftData.fullDate)).format("YYYY-MM-DD"),
          moment(new Date(shiftData.fullDate)).format("YYYY-MM-DD"),
          startTime,
          endTime,
          addNurseLicenseType,
          fullAddress,
          "1"
        );
      } else {
        getNurseList(
          orgLocation,
          orgLocationIdValue,
          moment(new Date(shiftData.fullDate)).format("YYYY-MM-DD"),
          moment(new Date(shiftData.fullDate)).format("YYYY-MM-DD"),
          startTime,
          endTime,
          addNurseLicenseType,
          fullAddress,
          "1"
        );
      }
    }
    if (addNurse && addNurseType === "2") {
      if (jobNewData2?.jobFinalNurseTable?.availableDate) {
        getNurseList(
          orgLocation,
          orgLocationIdValue,
          moment(new Date(shiftData.fullDate)).format("YYYY-MM-DD"),
          moment(new Date(shiftData.fullDate)).format("YYYY-MM-DD"),
          startTime2,
          endTime2,
          addNurseLicenseType,
          fullAddress,
          "2"
        );
      } else {
        getNurseList(
          orgLocation,
          orgLocationIdValue,
          moment(new Date(shiftData.fullDate)).format("YYYY-MM-DD"),
          moment(new Date(shiftData.fullDate)).format("YYYY-MM-DD"),
          startTime2,
          endTime2,
          addNurseLicenseType,
          fullAddress,
          "2"
        );
      }
    }
  }, [jobNewData, jobNewData2, addNurse, addNurseType, addNurseLicenseType]);

  const handleClick = (type) => {
    if (type === "1") {
      if (!jobNewData.jobFinalNurseTableId) {
        setAddNurse(true);
        setAddNurseType(type);
      } else if (
        !jobNewData?.jobFinalNurseTable?.nurseLoginControl ||
        jobNewData?.jobFinalNurseTable?.nurseAppAccessControl ||
        !jobNewData?.jobFinalNurseTable?.availableDate ||
        !jobNewData?.jobFinalNurseTable?.availableTime
      ) {
        setAddNurse(true);
        setAddNurseType(type);
      } else {
        setAddNurse(true);
        setAddNurseType(type);
      }
    } else if (type === "2") {
      if (!jobNewData2.jobFinalNurseTableId) {
        setAddNurse(true);
        setAddNurseType(type);
      } else if (
        !jobNewData2?.jobFinalNurseTable?.nurseLoginControl ||
        jobNewData2?.jobFinalNurseTable?.nurseAppAccessControl ||
        !jobNewData2?.jobFinalNurseTable?.availableDate ||
        !jobNewData2?.jobFinalNurseTable?.availableTime
      ) {
        setAddNurse(true);
        setAddNurseType(type);
      } else {
        setAddNurse(true);
        setAddNurseType(type);
      }
    }
  };

  const update = (element) => {
    if (addNurseType === "1") {
      getNurseWithId(
        element?.id,
        moment(new Date(shiftData.fullDate)).format("YYYY-MM-DD"),
        moment(new Date(shiftData.fullDate)).format("YYYY-MM-DD"),
        startTime,
        endTime
      ).then((res) => {
        if (res) {
          let data = {
            index,
            name: "shift_1",
            value: {
              ...shiftData.shift_1,
              jobFinalNurseTableId: res?.id,
              jobFinalNurseTable: res
            }
          };
          handleUpdate(data);
          setJobNewData({
            ...jobNewData,
            jobFinalNurseTableId: res?.id,
            jobFinalNurseTable: res
          });
          setLoadingShift1(false);
          setAddNurse(false);
          setAddNurseTypeButton("");
        } else {
          setLoadingShift1(false);
          setAddNurse(false);
          setAddNurseTypeButton("");
        }
      });
    } else if (addNurseType === "2") {
      getNurseWithId(
        element?.id,
        moment(new Date(shiftData.fullDate)).format("YYYY-MM-DD"),
        moment(new Date(shiftData.fullDate)).format("YYYY-MM-DD"),
        startTime2,
        endTime2
      ).then((res) => {
        if (res) {
          let data = {
            index,
            name: "shift_2",
            value: {
              ...shiftData.shift_2,
              jobFinalNurseTableId: res?.id,
              jobFinalNurseTable: res
            }
          };
          handleUpdate(data);
          setJobNewData2({
            ...jobNewData2,
            jobFinalNurseTableId: res?.id,
            jobFinalNurseTable: res
          });
          setLoadingShift2(false);
          setAddNurse(false);
          setAddNurseTypeButton("");
        } else {
          setLoadingShift2(false);
          setAddNurse(false);
          setAddNurseTypeButton("");
        }
      });
    }
  };

  const filter = (data) => {
    return data?.filter((item) =>
      item?.id?.name?.toString()?.toLowerCase()?.includes(searchTerm)
    );
  };

  return (
    <div>
      <div className="text-sm font-medium">
        <span>{new Date(shiftData.fullDate).toLocaleDateString()}-</span>
        <span>{moment(new Date(shiftData.fullDate)).format("dddd")}</span>
      </div>
      <div className="mt-2">
        {loadingShift1 ? (
          <div
            className={`flex flex-col mt-2 p-2 rounded-sm text-xs bg-gray-200`}
          >
            Loading...
          </div>
        ) : shiftData.shift_1 ? (
          <div
            className={`flex flex-col mt-2 p-2 rounded-sm text-xs ${
              jobNewData.jobFinalNurseTableId ? "bg-gray-100" : "bg-red-200"
            }`}
          >
            <span>{shiftData.shift_1.time}</span>
            <span>{getLicenseTypeView(shiftData.shift_1.role)}</span>
            <div
              className="flex-row items-center cursor-pointer"
              onClick={() => {
                handleClick("1");
                setAddNurseLicenseType([
                  getLicenseType(shiftData.shift_1.role)
                ]);
                if (!jobNewData.jobFinalNurseTableId) {
                  setAddNurseTypeButton("add");
                }
              }}
            >
              <span>
                {jobNewData.jobFinalNurseTable ? (
                  <div>
                    <NurseData nurse={jobNewData.jobFinalNurseTable} />
                    {/* {shiftData.shift_1.name} */}
                  </div>
                ) : shiftData.shift_1.name ? (
                  removeTitles(shiftData.shift_1.name)
                ) : (
                  ""
                )}
              </span>
              {!jobNewData.jobFinalNurseTableId ? (
                <div>
                  <div
                    className="bg-red-600 text-white flex
                    items-center justify-center py-1 rounded"
                  >
                    <MdAdd />
                    <p>Add Nurse</p>
                  </div>
                  <p className="text-center mt-1 text-xs">
                    Not Available in Database
                  </p>
                </div>
              ) : !jobNewData?.jobFinalNurseTable?.nurseLoginControl ||
                jobNewData?.jobFinalNurseTable?.nurseAppAccessControl ||
                !jobNewData?.jobFinalNurseTable?.availableDate ||
                !jobNewData?.jobFinalNurseTable?.availableTime ? (
                <div>
                  <div
                    className="bg-red-600 text-white flex
                    items-center justify-center py-1 rounded"
                  >
                    <MdEdit />
                    <p>Change Nurse</p>
                  </div>
                  <p className="text-center font-medium text-red-600 mt-1 text-xs">
                    Schedule Conflict
                  </p>
                </div>
              ) : (
                <div
                  className="bg-green-600 text-white flex
                    items-center justify-center py-1 rounded"
                >
                  <MdEdit />
                  <p>Change Nurse</p>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div
            className={`flex flex-col mt-2 p-2 rounded-sm text-xs ${
              jobNewData.jobFinalNurseTableId ? "bg-gray-100" : "bg-red-200"
            }`}
          >
            No data
          </div>
        )}
        {loadingShift2 ? (
          <div
            className={`flex flex-col mt-2 p-2 rounded-sm text-xs bg-gray-200`}
          >
            Loading...
          </div>
        ) : shiftData.shift_2 ? (
          <div
            className={`flex flex-col mt-2 p-2 rounded-sm text-xs ${
              jobNewData2.jobFinalNurseTableId ? "bg-gray-100" : "bg-red-200"
            }`}
          >
            <span>{shiftData.shift_2.time}</span>
            <span>{getLicenseTypeView(shiftData.shift_2.role)}</span>
            <div
              className="flex-row items-center cursor-pointer"
              onClick={() => {
                handleClick("2");
                setAddNurseLicenseType([
                  getLicenseType(shiftData.shift_2.role)
                ]);
                if (!jobNewData2.jobFinalNurseTableId) {
                  setAddNurseTypeButton("add");
                }
              }}
            >
              <span>
                {jobNewData2.jobFinalNurseTable ? (
                  <div>
                    <NurseData nurse={jobNewData2.jobFinalNurseTable} />
                    {/* {shiftData.shift_2.name} */}
                  </div>
                ) : shiftData.shift_2.name ? (
                  removeTitles(shiftData.shift_2.name)
                ) : (
                  ""
                )}
              </span>
              {!jobNewData2.jobFinalNurseTableId ? (
                <div>
                  <div
                    className="bg-red-600 text-white flex
                  items-center justify-center py-1 rounded"
                  >
                    <MdAdd />
                    <p>Add Nurse</p>
                  </div>
                  <p className="text-center mt-1 text-xs">
                    Not Available in Database
                  </p>
                </div>
              ) : !jobNewData2?.jobFinalNurseTable?.nurseLoginControl ||
                jobNewData2?.jobFinalNurseTable?.nurseAppAccessControl ||
                !jobNewData2?.jobFinalNurseTable?.availableDate ||
                !jobNewData2?.jobFinalNurseTable?.availableTime ? (
                <div>
                  <div
                    className="bg-red-600 text-white flex
                    items-center justify-center py-1 rounded"
                  >
                    <MdEdit />
                    <p>Change Nurse</p>
                  </div>
                  <p className="text-center font-medium text-red-600 mt-1 text-xs">
                    Schedule Conflict
                  </p>
                </div>
              ) : (
                <div
                  className="bg-green-600 text-white flex
                    items-center justify-center py-1 rounded"
                >
                  <MdEdit />
                  <p>Change Nurse</p>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div
            className={`flex flex-col mt-2 p-2 rounded-sm text-xs ${
              jobNewData2.jobFinalNurseTableId ? "bg-gray-100" : "bg-red-200"
            }`}
          >
            No data
          </div>
        )}
      </div>

      <Transition.Root show={addNurse} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={(e) => {
            setAddNurse(e);
            setNurseList(undefined);
            setAddNurseLicenseType("");
            setAddNurseTypeButton("");
          }}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className={`relative transform overflow-hidden rounded-lg
                 bg-white text-left shadow-xl transition-all sm:my-8 pr-10 ${
                   nurseList?.length === 0 ? "pb-24" : "pb-0"
                 }`}
                >
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                        <MdAdd className="text-blue-600 " />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900 mt-2"
                        >
                          Select Nurse for Shift {addNurseType}
                          <div className="py-3 flex justify-end">
                            <button
                              type="button"
                              className="my-3 ml-2 inline-flex w-full justify-center rounded-md bg-green-500
                              px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset
                            ring-gray-300 hover:bg-green-50 hover:text-gray-500 sm:mt-0 sm:w-auto text-white"
                              onClick={() => {
                                setAddRecord(true);
                              }}
                            >
                              Create Nurse
                            </button>
                            <button
                              type="button"
                              className="my-3 ml-2 inline-flex w-full justify-center rounded-md bg-gray-500
                              px-3 py-2 text-sm font-semibold shadow-sm ring-1 ring-inset
                            ring-gray-300 hover:bg-green-50 hover:text-gray-500 sm:mt-0 sm:w-auto text-white"
                              onClick={() => {
                                setAddNurse(false);
                                setNurseList(undefined);
                              }}
                            >
                              Cancel
                            </button>
                          </div>
                        </Dialog.Title>
                        <div className="flex space-x-2">
                          <input
                            type="text"
                            placeholder="Search by name..."
                            value={searchTerm}
                            onChange={(event) =>
                              setSearchTerm(event.target.value.toLowerCase())
                            }
                            className="border border-gray-300 text-black flex p-2
                            text-sm rounded mb-3 focus:border-gray-300 outline-gray-300"
                          />
                          <MultiSelect
                            selectedValue={addNurseLicenseType}
                            setSelectedValue={(event) => {
                              if (type === "1") {
                                let data = {
                                  index,
                                  name: "shift_1",
                                  value: {
                                    ...shiftData.shift_1,
                                    licenseTypeArr: event
                                  }
                                };
                                handleUpdate(data);
                                setJobNewData({
                                  ...jobNewData,
                                  licenseTypeArr: event
                                });
                                setAddNurseLicenseType(event);
                              } else {
                                let data = {
                                  index,
                                  name: "shift_2",
                                  value: {
                                    ...shiftData.shift_2,
                                    licenseTypeArr: event
                                  }
                                };
                                handleUpdate(data);
                                setJobNewData2({
                                  ...jobNewData2,
                                  licenseTypeArr: event
                                });
                                setAddNurseLicenseType(event);
                              }
                            }}
                          />
                        </div>
                        {addNurseTypeButton === "add" && (
                          <div
                            className={`flex flex-col mt-2 p-2 rounded-sm text-xs font-medium text-blue-500`}
                          >
                            Assign from existing Nurses
                          </div>
                        )}
                        <div className="mt-2">
                          {loadingNurseList ? (
                            <div
                              className={`flex flex-col mt-2 p-2 rounded-sm text-xs`}
                            >
                              Loading...
                            </div>
                          ) : nurseList?.length === 0 ? (
                            <div
                              className={`flex flex-col mt-2 p-2 rounded-sm text-xs`}
                            >
                              List is empty.
                            </div>
                          ) : (
                            filter(nurseList)
                              ?.sort((a, b) =>
                                a?.id?.name
                                  .toLowerCase()
                                  .localeCompare(b?.id?.name.toLowerCase())
                              )
                              ?.map((item, index) => {
                                return (
                                  <UserJobCard
                                    key={index}
                                    element={item}
                                    jobFinalSelectionNurseId={
                                      addNurseType === "1"
                                        ? jobNewData?.jobFinalNurseTableId
                                        : addNurseType === "2"
                                        ? jobNewData2?.jobFinalNurseTableId
                                        : null
                                    }
                                    setJobFinalSelectionNurseId={(element) =>
                                      update(element)
                                    }
                                  />
                                );
                              })
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <Transition.Root show={addRecord} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={(e) => setAddRecord(e)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div
              className="flex min-h-full 
            items-end justify-center p-4 
            text-center sm:items-center sm:p-0"
            >
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel
                  className="relative transform overflow-hidden rounded-lg
                 bg-white text-left shadow-xl transition-all mx-16 my-5 w-full"
                >
                  <div className="px-4 flex-1 bg-white  pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <div className="mt-5">
                        <NurseForm
                          jobNewData={nurseData}
                          handleInputChangeNewData={handleInputChangeNewData}
                          edit={undefined}
                          orgLocationIdValue={orgLocationIdValue}
                          address={address}
                          setAddress={setAddress}
                        />
                      </div>
                    </div>
                  </div>
                  {!address && (
                    <div
                      className="bg-white px-4 pb-6 sm:flex
                   sm:flex-row-reverse sm:px-6"
                    >
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md
                       bg-green-600 px-3 py-2 text-sm font-semibold text-white
                        shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                        onClick={() => postNurse(setAddRecord)}
                      >
                        Create User
                      </button>
                      <button
                        type="button"
                        className="mt-3 inline-flex w-full justify-center
                       rounded-md bg-white px-3 py-2 text-sm font-semibold
                        text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300
                         hover:bg-gray-50 sm:mt-0 sm:w-auto"
                        onClick={() => {
                          setAddRecord(false);
                          setNurseData({
                            email: "", //
                            firstName: "", //
                            lastName: "", //
                            password: "", //
                            phoneNumber: "", //
                            gender: "", //
                            dateOfBirth: date18YearsAgo, //
                            primaryState: "", //
                            fullAddress: "",
                            latitude: "",
                            longitude: "",
                            organization: "", //
                            location_id: "", //
                            primaryLicenseType: "", //
                            primaryLicenseMultiPrivilege: false, //
                            clinicalLicenseNumber: "", //
                            clinicalLicenseExpirationDate: new Date(), //
                            nurseVerified: false, //
                            emailVerified: false, //,
                            nurseLoginControl: false,
                            nurseAppAccessControl: false
                          });
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default Day;
