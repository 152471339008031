import { API_URL } from "./config";

export async function getNurseNotification(authToken, userId) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/nurse-notification/all_notification/list/${userId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching");
  }
  return await res.json();
}

export async function getNurseNotificationDetails(authToken, id) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/nurse-notification/${id}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching");
  }
  return await res.json();
}

export const postNurseNotification = async (
  authToken,
  id,
  imageURL,
  title,
  content,
  navigationScreen,
  navigationData,
  visited,
  visitNotification,
  notificationDotTypeColor,
  url,
) => {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/nurse-notification`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "Application/json",
    },
    body: JSON.stringify({
      id: id,
      imageURL,
      title,
      content,
      navigationScreen,
      navigationData,
      visited,
      visitNotification,
      notificationDotTypeColor,
      url,
    }),
  });
  if (res.status === 400) {
    throw new Error("Error during posting.");
  } else {
    return res.json();
  }
};

export const updateNurseNotification = async (
  authToken,
  id,
  imageURL,
  title,
  content,
  navigationScreen,
  navigationData,
  visited,
  visitNotification,
  notificationDotTypeColor,
  url
) => {

  if (!authToken) {
    return;
  }
  const res = await fetch(`${API_URL}/nurse-notification/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json", // "application/json" should be lowercase
    },
    body: JSON.stringify({
      imageURL,
      title,
      content,
      navigationScreen,
      navigationData,
      visited,
      visitNotification,
      notificationDotTypeColor,
      url,
    }),
  });

  if (res.status === 400) {
    throw new Error("Network error");
  } else {
    return res.json();
  }
};