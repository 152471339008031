import { API_URL } from "./config";

export const authLogin = async ({ email, password }) => {
  // console.log(JSON.stringify({ email, password }));
  // console.log(`${API_URL}/auth-facility/login-and-authenticate`)

  const res = await fetch(`${API_URL}/auth-organization/login-and-authenticate`, {
    method: "POST",
    headers: {
      "Content-type": "Application/json",
    },
    body: JSON.stringify({ email, password }),
  });
  // console.log(res);
  if (res.status === 400) {
    throw new Error("User is not found");
  } else if (res.status === 401) {
    throw new Error("User is not confirmed!");
  } else if (res.status === 402) {
    throw new Error("Invalid email or password");
  } else if (res.status !== 200) {
    throw new Error("Error during the login process");
  } else {
    return res.json();
  }
};
