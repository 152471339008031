import { API_URL } from "../api_url/config"

export const sendNotification = async (authToken, data) => {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/notification`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "Application/json",
    },
    body: JSON.stringify(data)
  });
  if (res.status === 400) {
    throw new Error("Error during send.");
  } else {
    return res.json();
  }
};
