import React, { Fragment } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { LuChevronsUpDown } from "react-icons/lu";
import { MdDone } from "react-icons/md";

export default function List({ data, handleInputChangeNewData, options }) {
  return (
    <div className="">
      <Listbox
        value={data}
        onChange={(e) => {
          handleInputChangeNewData(e?.name)
        }}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full cursor-default rounded-lg
           bg-blue-600 py-2 pl-3 pr-10 text-left shadow-md focus:outline-none
            focus-visible:border-indigo-500 focus-visible:ring-2
             focus-visible:ring-white/75 focus-visible:ring-offset-2
              focus-visible:ring-offset-orange-300 sm:text-sm
              text-white font-medium">
            <span className="block truncate text-base">{data?.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
              <LuChevronsUpDown />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {options.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-amber-100 text-amber-900' : 'text-gray-900'
                    }`
                  }
                  value={person}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                          }`}
                      >
                        {person.name}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <MdDone />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}
