import React, { Fragment, useState } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { LuChevronsUpDown } from "react-icons/lu";
import { MdDone } from "react-icons/md";

export default function AutoComplete({ state, handleInputChangeNewData, option }) {

  const [selected, setSelected] = useState(state?.customerName !== "" ?
    option?.filter(i => (i?.firstName + " " + i?.lastName) === state?.customerName)[0] :
    "Select Customer")
  const [query, setQuery] = useState('')

  const filteredOption =
    query === ''
      ? option
      : option.filter((person) =>
        person.firstName
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, '')) ||
        person.lastName
          .toLowerCase()
          .replace(/\s+/g, '')
          .includes(query.toLowerCase().replace(/\s+/g, ''))
      )


  const onPress = async (item) => {
    // let address =
    //   item?.patient_address_line_1 +
    //   "," +
    //   item?.patient_city +
    //   "," +
    //   item?.patient_state +
    //   "," +
    //   item?.patient_zip_code;

    // const GOOGLE_PLACES_API_KEY =
    //   "AIzaSyCuH8GUhp24JHpPyQeX0N-Alowiiu6e3xM";
    // const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
    //   address
    // )}&key=${GOOGLE_PLACES_API_KEY}`;

    try {
      // const response = await fetch(apiUrl);
      // const data = await response.json();
      // const state = data.results[0].address_components.find((component) =>
      //   component.types.includes("administrative_area_level_1")
      // );
      handleInputChangeNewData({
        target: {
          name: "fullAddress",
          value: item?.fullAddress
        },
      })
      handleInputChangeNewData({
        target: {
          name: "latitude",
          value: item?.latitude,
        },
      });
      handleInputChangeNewData({
        target: {
          name: "longitude",
          value: item?.longitude,
        },
      });
      handleInputChangeNewData({
        target: {
          name: "customerName",
          value: item?.firstName + " " + item?.lastName,
        },
      });
      handleInputChangeNewData({
        target: {
          name: "customerId",
          value: item?.id
        },
      });

    } catch (error) {
      console.error("Error:", error);
      return null;
    }

  }

  return (
    <div>
      <Combobox value={selected} onChange={e => {
        setSelected(e)
        onPress(e)
      }}>
        <div className="relative mt-1">
          <div className="relative w-full cursor-default overflow-hidden rounded-lg
           bg-white text-left shadow-md focus:outline-none focus-visible:ring-2
            focus-visible:ring-white/75 focus-visible:ring-offset-2 
            focus-visible:ring-offset-teal-300 sm:text-sm">
            <Combobox.Input
              className="w-full border-none py-2 pl-3 pr-10 text-sm leading-5
               text-gray-900 focus:ring-0 outline-0"
              placeholder="Select Customer"
              displayValue={(person) => selected === "Select Customer" ? "" :
                person.firstName + " " + person.lastName}
              onChange={(event) => {
                setQuery(event.target.value)
              }}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <LuChevronsUpDown
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute mt-1 z-10 max-h-60 w-full overflow-auto rounded-md 
            bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {filteredOption.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                filteredOption.map((person) => (
                  <Combobox.Option
                    key={person.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? 'bg-teal-600 text-white' : 'text-gray-900'
                      }`
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`block truncate ${selected ? 'font-medium' : 'font-normal'
                            }`}
                        >
                          {person.firstName + " " + person.lastName}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'text-teal-600'
                              }`}
                          >
                            <MdDone />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}
