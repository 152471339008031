import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { useAuth } from "../context/AuthContext";
import { getReportAll, getReportDaily, getReportDownloadAll } from "../api_url/report";
import moment from "moment";

// Example Data
// const data = [
//   ["Date", "Posted", "Accepted"],
//   ["01/05/2024", 50, 65],
//   ["02/05/2024", 40, 55],
//   ["03/05/2024", 70, 80],
//   ["04/05/2024", 55, 70],
//   ["05/05/2024", 60, 85],
//   ["06/05/2024", 30, 45],
//   ["07/05/2024", 90, 120],
// ];

export function ChartView() {
  const { authToken, userId } = useAuth();

  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(undefined);
  const [date, setDate] = useState("");
  const [downloadData, setDownloadData] = useState(undefined);
  const [loadingBar, setLoadingBar] = useState(false);
  const [dataBar, setDataBar] = useState(undefined);
  const [change, setChange] = useState(false);

  const getDatesForNextTwoMonths = () => {
    const result = [];
    const currentDate = new Date();
    const futureDate = new Date();
    futureDate.setMonth(currentDate.getMonth() + 2);
    futureDate.setDate(futureDate.getDate() - 1);

    while (currentDate <= futureDate) {
      const min = Math.round((Math.random() * 9 + 1)) * 10; // Random Min value rounded to nearest ten
      const max = min + Math.round(Math.random() * 10) * 10; // Random Max value greater than Min, rounded to nearest ten
      const value = Math.round((Math.random() * (max - min) + min) / 10) * 10; // Random Value between Min and Max, rounded to nearest ten

      result.push([currentDate.toISOString().split('T')[0], min, value, max]);
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return result;
  };

  const getReport = async () => {
    await getReportAll(authToken)
      .then((res) => {
        if (res) {
          if (change) {
            const datesArray = getDatesForNextTwoMonths();
            setData([["Date", "Posted", "Accepted", "Max Allowed"],
            ...datesArray]);
          } else {
            const finalResult = [["Date", "Posted", "Accepted", "Max Allowed"],
            ...res];
            setData(finalResult);
          }
          setLoading(false);
        }
      })
      .catch((e) => {
        console.log(e)
      });
    await getReportDownloadAll(authToken)
      .then((res) => {
        if (res) {
          setDownloadData(res?.data);
        }
      })
      .catch((e) => {
        console.log(e)
      });
  }

  const getDailyReport = async (date) => {
    setLoadingBar(true);
    await getReportDaily(authToken, date)
      .then((res) => {
        if (res) {
          const finalResult = [["License", "Posted", "Accepted", "Max Allowed"],
          ...res];
          setDataBar(finalResult);
          setLoadingBar(false);
        }
      })
      .catch((e) => {
        console.log(e)
      });
  }

  useEffect(() => {
    getReport();
  }, [authToken, change]);

  useEffect(() => {
    if (date !== "") {
      getDailyReport(date);
    }
  }, [authToken, date]);

  const chartEvents = [
    {
      eventName: "select",
      callback({ chartWrapper }) {
        // console.log("Selected ", chartWrapper.getChart().getSelection());
        var selectedItem = chartWrapper.getChart().getSelection()[0];
        // console.log(selectedItem.row) 
        if (selectedItem) {
          var topping = data[selectedItem.row + 1][0]
          setDate(topping);
        }
      }
    }
  ];

  const optionsBar = {
    title: `${date} Report`,
    chartArea: { width: "50%" },
    isStacked: true,
    hAxis: {
      title: "Amount Difference",
      minValue: 0,
    },
    vAxis: {
      title: "License",
    },
  };

  const optionsLine = {
    title: "Bid Report",
    curveType: "function",
    legend: { position: "bottom" },
  };

  const downloadCSVFile = (data) => {
    let current = moment().format("MM-DD-YYYY");
    // Create a Blob object for the CSV data
    const blob = new Blob([data], { type: "text/csv" });

    // Create a temporary URL for the Blob
    const url = URL.createObjectURL(blob);

    // Create a link element
    const link = document.createElement("a");
    link.href = url;
    link.download = `JobList-${current}.csv`;

    // Trigger the download
    link.click();

    // Clean up the temporary URL
    URL.revokeObjectURL(url);
  };

  function convertToCSV(jsonData) {
    const separator = ",";
    const keys = Object.keys(jsonData[0]);
    const csvContent = [
      keys.join(separator),
      ...jsonData.map((item) => {
        // Extract the 'FullAddress' and 'LicenseType' properties
        const {
          fullAddress,
          licenseType,
          certification,
          jobAcceptedNurses,
          EMRorEHRExperience,
          bidNurseArray,
          nurseCancelNotes,
          checkInFullAddress,
          checkOutFullAddress,
          checkInFullAddressNurse,
          checkOutFullAddressNurse
          , ...rest } = item;

        // Join the values of the other properties with the 'FullAddress' and 'LicenseType' values
        const values = keys.map((key) =>
          key === "fullAddress"
            ? `"${fullAddress}"`
            : key === "checkInFullAddress"
              ? `"${checkInFullAddress}"`
              : key === "checkOutFullAddress"
                ? `"${checkOutFullAddress}"`
                : key === "checkInFullAddressNurse"
                  ? `"${checkInFullAddressNurse}"`
                  : key === "checkOutFullAddressNurse"
                    ? `"${checkOutFullAddressNurse}"`
                    : key === "certification"
                      ? `"${certification}"`
                      : key === "jobAcceptedNurses"
                        ? `"${jobAcceptedNurses}"`
                        : key === "EMRorEHRExperience"
                          ? `"${EMRorEHRExperience}"`
                          : key === "bidNurseArray"
                            ? `"${bidNurseArray}"`
                            : key === "nurseCancelNotes"
                              ? `"${nurseCancelNotes}"`
                              : key === "licenseType"
                                ? `"${licenseType.join(", ")}"`
                                : rest[key]
        );

        return values.join(separator);
      }),
    ].join("\n");

    return csvContent;
  }

  return (
    <div className="overflow-hidden">
      {
        loading ?
          <h6 className="text-center mt-10">Loading...</h6>
          :
          <div>
            <div className="flex justify-end m-5">
              {!change && downloadData !== undefined &&
                <button
                  className="bg-green-600
                 text-white px-5 py-1 rounded-md"
                  onClick={() => downloadCSVFile(convertToCSV(downloadData))}
                >
                  Download
                </button>}
              {downloadData !== undefined &&
                <button
                  className="bg-green-600
                 text-white px-5 py-1 rounded-md ml-2"
                  onClick={() => setChange(!change)}
                >
                  {!change ? "Change Random" : "Change Server"}
                </button>}
            </div>

            {change ?
              <Chart
                chartType="LineChart"
                width="100%"
                height="400px"
                data={data}
                options={optionsLine}
              /> :
              <Chart
                chartType="LineChart"
                width="100%"
                height="400px"
                data={data}
                options={optionsLine}
                chartEvents={chartEvents}
              />}

            {loadingBar ?
              <h6 className="text-center mt-10">Loading...</h6>
              :
              (!change && dataBar !== undefined &&
                <Chart
                  chartType="BarChart"
                  width="100%"
                  height="400px"
                  data={dataBar}
                  options={optionsBar}
                />)
            }
          </div>
      }
    </div>
  );
}
