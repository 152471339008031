import OpenAI from "openai";
const REACT_APP_OPENAI_API_KEY =
  "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";

// Initialize OpenAI client with API key
const client = new OpenAI({
  apiKey: REACT_APP_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

export const fetchScheduleData = async (extractedText) => {
  try {
    const response = await client.chat.completions.create({
      model: "gpt-4o-mini", // Use a model appropriate for your task
      response_format: { type: "json_object" },
      messages: [
        {
          role: "system",
          content: "You are a helpful assistant designed to output JSON."
        },
        {
          role: "user",
          content: `Convert the following schedule text data to JSON format example like 
          {
            organization: 'ELITE CARE MANAGEMENT, INC (HC)',
            customer: 'BRUMLEY, CEDRIC',
            MRN:'005',
            data:[
              {
                "month": "July",
                "date": "01",
                "shift_1": {
                  "time": "7:00 AM-7:00 PM",
                  "role": "Registered Nurse",
                  "name": "Vanessa Lamarre RN"
                },
                "shift_2": {
                  "time": "7:00 PM-7:00 AM",
                  "role": "HOME HEALTH AIDE",
                  "name": "Adedoyin 'Dee' Okewole CNA"
                }
              },
              ....
            ]
          } and order needs to start date 01 to end of that month if any previous month date just ignore
            : ${extractedText}`
        }
      ],
      max_tokens: extractedText?.length
    });
    // console.log(response);
    const result = response?.choices[0]?.message?.content;
    console.log(JSON.parse(result));
    return JSON.parse(result);
  } catch (error) {
    console.error(
      "Error fetching schedule data:",
      error.response || error.message
    );
  }
};
