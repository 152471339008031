import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import moment from "moment";
import { useAuth } from "../../context/AuthContext";
import { postJobTable } from "../../api_url/jobTable";
import { getCustomerId } from "../../api_url/customerTable";
import { sendNotification } from "../../api_url/webNotification";

function UploadTable({
  columns,
  datas,
  setColumns,
  setJsonData,
  setFile,
  orgLocation,
  orgLocationIdValue,
  jobPostingTableFacilityTableId,
  nurseList
}) {
  const { authToken, userId } = useAuth();
  // const uniqueCSVFileArr = datas?.filter((item, index, array) => {
  //   return (
  //     array.findIndex((element) => {
  //       return (
  //         element.patient_first_name === item.patient_first_name &&
  //         element.patient_last_name === item.patient_last_name &&
  //         element.patient_address_line_1 === item.patient_address_line_1 &&
  //         element.patient_city === item.patient_city &&
  //         element.patient_state === item.patient_state &&
  //         element.patient_zip_code === item.patient_zip_code
  //       );
  //     }) === index
  //   );
  // });

  // // Find items in CSVFileArr that are not present in ServerArr
  // const data = uniqueCSVFileArr.filter((item) => {
  //   return !orgLocationIdData.some((serverItem) => {
  //     return (
  //       serverItem.patient_first_name === item.patient_first_name &&
  //       serverItem.patient_last_name === item.patient_last_name &&
  //       serverItem.patient_address_line_1 === item.patient_address_line_1 &&
  //       serverItem.patient_city === item.patient_city &&
  //       serverItem.patient_state === item.patient_state &&
  //       serverItem.patient_zip_code === item.patient_zip_code
  //     );
  //   });
  // });

  // The 'notMatchedItems' array contains the items in CSVFileArr that are not present in ServerArr

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [alert, setAlert] = useState(false);
  const [rowLength, setRowLength] = useState(0);
  const [invalidRowss, setInvalidRowss] = useState(undefined);

  const [editedData, setEditedData] = useState(() => {
    // create missing fields with empty string
    return datas.map((item) =>
      columns.reduce((acc, curr) => {
        const accessor = curr.accessor.replace(/\s+/g, "_").toLowerCase();
        acc[accessor] = item[curr.accessor] || "";
        return acc;
      }, {})
    );
  });

  const [columnsData, setColumnsData] = useState(() => {
    return columns.map((column) => {
      return {
        Header: column.Header,
        accessor: column.accessor.toLowerCase().replace(/ /g, "_"),
      };
    });
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [emptyFields, setEmptyFields] = useState([]);

  const handleInputChange = (event, index) => {
    const { name, value } = event.target;
    const newData = [...editedData];
    newData[index][name] = value;
    setEditedData(newData);
  };

  const validateFields = () => {
    let isValid = true;
    const newData = [...editedData];
    const invalidRows = [];
    const emptyFields = [];

    for (let i = 0; i < newData.length; i++) {
      const data = newData[i];
      const title = data?.title?.toString();
      const jobtype = data?.jobtype?.toString();
      const startdate = data?.startdate?.toString();
      const enddate = data?.enddate?.toString();
      const starttime = data?.starttime?.toString();
      const endtime = data?.endtime?.toString();
      const Break = data?.break?.toString();
      const customername = data?.customername?.toString();
      const fulladdress = data?.fulladdress?.toString();
      const customervisibility = data?.customervisibility?.toString();

      if (
        title === "" ||
        jobtype === "" ||
        startdate === "" ||
        enddate === "" ||
        starttime === "" ||
        endtime === "" ||
        Break === "" ||
        customername === "" ||
        fulladdress === "" ||
        customervisibility === ""
      ) {
        isValid = false;
        invalidRows.push(data);
      }
    }

    for (let i = 0; i < newData.length; i++) {
      const title = newData[i]["title"];
      const jobtype = newData[i]["jobtype"];
      const startdate = newData[i]["startdate"];
      const enddate = newData[i]["enddate"];
      const starttime = newData[i]["starttime"];
      const endtime = newData[i]["endtime"];
      const Break = newData[i]["break"];
      const customername = newData[i]["customername"];
      const fulladdress = newData[i]["fulladdress"];
      const customervisibility = newData[i]["customervisibility"];
      if (
        title === "" ||
        jobtype === "" ||
        startdate === "" ||
        enddate === "" ||
        starttime === "" ||
        endtime === "" ||
        Break === "" ||
        customername === "" ||
        fulladdress === "" ||
        customervisibility === ""
      ) {
        isValid = false;
        emptyFields.push(
          `title_${i}`,
          `jobtype_${i}`,
          `startdate_${i}`,
          `enddate_${i}`,
          `starttime_${i}`,
          `endtime_${i}`,
          `break_${i}`,
          `customername_${i}`,
          `fulladdress_${i}`,
          `customervisibility_${i}`,
        );
      }
    }

    setEmptyFields(emptyFields);

    return { isValid, invalidRows };
  };

  function timeDifferent(sTime, eTime) {
    const startTime = moment(moment(new Date(sTime)).format("hh:mma"), "hh:mm A");

    const start = moment(new Date(sTime));
    const end = moment(new Date(eTime));

    const endTime = moment(moment(new Date(eTime)).format("hh:mma"), "hh:mm A");
    const endTimeEnd = moment(
      moment(new Date(eTime)).format("hh:mma"),
      "hh:mm A"
    ).add(1, "day");

    const duration = moment.duration(endTime.diff(startTime));
    const durationEnd = moment.duration(endTimeEnd.diff(startTime));

    const hours = Math.floor(duration.asHours());
    const hoursEnd = Math.floor(durationEnd.asHours());
    const minutes = Math.floor(duration.asMinutes()) - hours * 60;

    // const onlyHours = `${
    //   end.hour() <= 12 && start.hour() <= 12
    //     ? hours
    //     : end.hour() <= 12
    //     ? hoursEnd
    //     : hours
    // } ${
    //   end.hour() <= 12 && start.hour() <= 12
    //     ? hours <= 1
    //       ? "hour"
    //       : "hours"
    //     : end.hour() <= 12
    //     ? hoursEnd <= 1
    //       ? "hour"
    //       : "hours"
    //     : hours <= 1
    //     ? "hour"
    //     : "hours"
    // }`;

    const onlyHours = `${end.hour() <= 12 && start.hour() <= 12
      ? hours
      : end.hour() <= 12
        ? hoursEnd
        : hours
      }${hours <= 1 ? "hour" : "hours"} ${minutes <= 0 ? "" : `${minutes}minutes`}`;

    return onlyHours;
  }

  const postJob = async (
    licenseType,
    shiftTitle,
    startDate,
    endDate,
    startTime,
    endTime,
    jBreak,
    selectCustomer,
    unit,
    floor,
    fullAddress,
    latitude,
    longitude,
    notes,
    jobType,
    jobTiming,
    customerVisibility,
    customerID,
  ) => {
    // console.log(
    //   licenseType,
    //   shiftTitle,
    //   startDate,
    //   endDate,
    //   startTime,
    //   endTime,
    //   jBreak,
    //   selectCustomer,
    //   unit,
    //   floor,
    //   fullAddress,
    //   latitude,
    //   longitude,
    //   notes,
    //   jobType,
    //   jobTiming,
    //   customerVisibility,
    //   customerID,
    // )
    try {
      const item = await postJobTable(
        authToken,
        licenseType,
        shiftTitle,
        "",
        [],
        "",
        "0",
        moment(startDate).format("YYYY-MM-DD"),
        moment(endDate).format("YYYY-MM-DD"),
        GetDateMergedTime(startDate, startTime.toString()).toString(),
        GetDateMergedTime(endDate, endTime.toString()).toString(),
        jBreak,
        customerID === null ? "" : selectCustomer,
        customerID === null ? "" : customerID?.toString(),
        unit,
        floor,
        fullAddress,
        latitude.toString(),
        longitude.toString(),
        "1",
        false,
        notes,
        jobType,
        "Open",
        "",
        "",
        [],
        false,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        timeDifferent(startTime, endTime),
        jobTiming,
        "",
        [],
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        false,
        false,
        false,
        false,
        false,
        customerVisibility,
        "",
        "",
        {},
        "",
        jobPostingTableFacilityTableId.toString(),
        false,
        "",
        "",
        "",
        false,
        false,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        GetDateMergedTime(startDate, startTime.toString()).valueOf().toString(),
        "",
        "",
        "",
        "",
        orgLocation,
        orgLocationIdValue,
      )
      return item;
    } catch (error) {
      console.log("data save error:", error);
    }
  };

  const deepEqual = (obj1, obj2) => {
    if (obj1 === obj2) {
      return true;
    }

    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      if (!obj2.hasOwnProperty(key)) {
        return false;
      }

      if (!deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }

    return true;
  };

  const getAddress = async (data) => {
    let address = data;

    const GOOGLE_PLACES_API_KEY =
      "AIzaSyCuH8GUhp24JHpPyQeX0N-Alowiiu6e3xM";
    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      address
    )}&key=${GOOGLE_PLACES_API_KEY}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      let obj = {
        latitude: data.results[0].geometry.location.lat,
        longitude: data.results[0].geometry.location.lng,
      }

      return obj

    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  }

  const getCustomer = async (name) => {

    try {
      const response = await getCustomerId(authToken, name);

      return response ? response?.id : ""

    } catch (error) {
      return null;
    }
  }

  function GetDateMergedTime(date, time) {
    // console.log(date, time);

    // Assuming date is in the format DD-MM-YYYY
    const date1 = moment(date, 'DD-MM-YYYY').format('DD-MM-YYYY');

    const time1 = moment(time, 'hh:mm A').format('hh:mm A');

    // console.log(date1, time1)

    const mergedTime = moment(`${date1} ${time1}`, 'DD-MM-YYYY hh:mm A');

    // console.log(mergedTime.format('DD-MM-YYYY hh:mm A'));

    return mergedTime;
  }

  const handleUpload = async () => {
    const { isValid, invalidRows } = validateFields();

    const validRows = editedData.filter((data) => {
      // Check if the row object is not present in the invalidRows array
      return !invalidRows.some((invalidRow) => deepEqual(invalidRow, data));
    });

    // console.log("valid rows:", validRows);
    // console.log("isValid:", isValid);
    // console.log("Invalid rows:", invalidRows);
    // console.log("emptyFields:", emptyFields);

    if (validRows?.length > 0) {
      setLoading(true);

      const postCustomerPromise = (data) => {
        return new Promise(async (resolve, reject) => {

          let address = await getAddress(data?.fulladdress)
          let customerID = await getCustomer(data.customername)

          const licenseTypeArray = data?.licensetype?.split(',').map(type => type.trim())

          const ct = new Date(GetDateMergedTime(data?.startdate, data?.starttime).toString());
          const currentHour = ct.getHours();
          let jobTiming =
            (currentHour >= 5 && currentHour < 12) ? "Morning" :
              (currentHour >= 12 && currentHour < 17) ? "Afternoon" :
                (currentHour >= 17 && currentHour < 20) ? "Evening" :
                  "Night";
          postJob(
            licenseTypeArray,
            data.title,
            moment(data.startdate).format("YYYY-MM-DD"),
            moment(data.enddate).format("YYYY-MM-DD"),
            GetDateMergedTime(moment(data.startdate).format("DD-MM-YYYY"), data.starttime).toString(),
            GetDateMergedTime(moment(data.enddate).format("DD-MM-YYYY"), data.endtime).toString(),
            data.break,
            data.customername,
            data.unit,
            data.floor,
            data.fulladdress,
            address.latitude.toString(),
            address.longitude.toString(),
            data?.notes,
            data?.jobtype,
            jobTiming,
            data.customervisibility === "Yes" ? true : false,
            customerID,
          )
            .then((res) => {
              // console.log(res);
              resolve(res); // Resolve the promise with the response
            })
            .catch((error) => {
              console.error(error);
              reject(error); // Reject the promise with the error
            });
        });
      };

      setRowLength(validRows?.length);
      // console.log(validRows?.length);
      Promise.all(validRows.map(postCustomerPromise))
        .then((responses) => {
          setLoading(false);
          setInvalidRowss(invalidRows);
          setAlert(true);
          nurseList?.map(async (item) => {
            if (item?.mobileId) {
              sendNotification(authToken, {
                expoPushToken: item?.mobileId,
                title: "New Jobs",
                body: `New Jobs available in your area.`,
                data: { screen: "HomeScreen" },
              })
            }
          });
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    }
  };

  const successOk = () => {
    if (invalidRowss.length > 0) {
      setError(true);
      setEditedData(invalidRowss);
      setAlert(false);
    } else {
      setError(false);
      setColumns([]);
      setJsonData([]);
      setEditedData([]);
      setFile("");
      window.location.reload();
      setAlert(false);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = editedData.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(editedData.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

  const handleNextPage = () => {
    if (currentPage < pageNumbers.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div>
      <div style={{ overflowX: "auto" }} className="mx-5">
        {error && (
          <p className="mb-4 text-red-500">
            Please review the record, fill in any missing fields and then
            upload.
          </p>
        )}
        <table class="w-full text-sm text-left rtl:text-right text-gray-500">
          <thead class="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              {columnsData.map((column) => (
                <th
                  key={column.accessor}
                  style={{ border: "1px solid black", padding: "8px" }}
                >
                  {column.Header === "LicenseType"
                    ? "license_Type*"
                    : column.Header === "Title"
                      ? "title*"
                      : column.Header === "JobType"
                        ? "job_type*"
                        : column.Header === "StartDate"
                          ? "start_date* (MM-DD-YYYY)"
                          : column.Header === "EndDate"
                            ? "end_date* (MM-DD-YYYY)"
                            : column.Header === "StartTime"
                              ? "start_time*"
                              : column.Header === "EndTime"
                                ? "end_time*"
                                : column.Header === "CustomerName"
                                  ? "customer_name*"
                                  : column.Header === "FullAddress"
                                    ? "full_address*"
                                    : column.Header === "Break"
                                      ? "break*"
                                      : column.Header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr class="bg-white border-b
               hover:bg-gray-50"
                key={index}>
                {columnsData.map((column) => (
                  <td
                    key={column.accessor}
                    style={{ border: "1px solid black", padding: "8px" }}
                  >
                    <input
                      type="text"
                      name={column.accessor}
                      value={item[column.accessor] || ""}
                      onChange={(event) => handleInputChange(event, index)}
                      style={
                        emptyFields.includes(`${column.accessor}_${index}`)
                          ? { border: "1px solid red", padding: "8px" }
                          : { border: "1px solid black", padding: "8px" }
                      }
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="space-x-3 mr-5 flex justify-end mt-5 items-center">
        <div>
          <label>Items per page:</label>
          <select
            value={itemsPerPage}
            onChange={(event) => setItemsPerPage(event.target.value)}
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
        </div>
        <button
          onClick={() => handlePrevPage()}
          className={`px-5 py-1 rounded-md ${currentPage > 1
            ? "bg-blue-500 text-white"
            : "bg-slate-100 text-black cursor-not-allowed"
            }`}
        >
          pre
        </button>
        <p>{currentPage}</p>
        <button
          onClick={() => handleNextPage()}
          className={`px-5 py-1 rounded-md ${currentPage < pageNumbers.length
            ? "bg-blue-500 text-white"
            : "bg-slate-100 text-black cursor-not-allowed"
            }`}
        >
          next
        </button>
      </div>

      {currentItems?.length > 0 && (
        <div className="flex justify-end mt-5 mr-5 items-center">
          <button
            className="bg-green-600 my-5 text-white px-5 py-1 rounded-md"
            // onClick={handleUpload}
            onClick={loading ? null : handleUpload}
          >
            {/* {"Upload"} */}
            {loading ? "Loading..." : "Upload"}
          </button>
        </div>
      )}
      <Transition.Root show={alert} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={(e) => setAlert(e)}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Success
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            {`${rowLength} ${rowLength > 1 ? "records" : "record"
                              } updated successfully.`}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center 
                      rounded-md bg-green-600 px-6 py-2 text-sm font-semibold 
                      text-white shadow-sm hover:bg-green-500 sm:ml-3 sm:w-auto"
                      onClick={() => successOk()}
                    >
                      OK
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
}

export default UploadTable;
