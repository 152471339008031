// import { Switch } from '@headlessui/react'
import { Checkbox1Presentation } from './Switch'
import React from 'react'
import List from './List'
import { DatePicker1Presentation } from './DatePicker'
import { DateOfBirth } from './DateOfBirth'
import { MdOutlineAdd } from 'react-icons/md'
import AutoFillAddress from '../address/AutoFillAddress'

function NurseForm({
  jobNewData,
  handleInputChangeNewData,
  edit,
  orgLocationIdValue,
  address,
  setAddress
}) {

  const option = [
    { name: "LPN" },
    { name: "CNA" },
    { name: "HHA" },
    { name: "RN" }
  ]

  const gender = [
    { name: "Male" },
    { name: "Female" },
    { name: "Others" }
  ]

  // const USAState = [
  //   { name: "Alabama" },
  //   { name: "Alaska" },
  //   { name: "Arizona" },
  //   { name: "Arkansas" },
  //   { name: "California" },
  //   { name: "Colorado" },
  //   { name: "Connecticut" },
  //   { name: "Delaware" },
  //   { name: "Florida" },
  //   { name: "Georgia" },
  //   { name: "Hawaii" },
  //   { name: "Idaho" },
  //   { name: "Illinois" },
  //   { name: "Indiana" },
  //   { name: "Iowa" },
  //   { name: "Kansas" },
  //   { name: "Kentucky" },
  //   { name: "Louisiana" },
  //   { name: "Maine" },
  //   { name: "Maryland" },
  //   { name: "Massachusetts" },
  //   { name: "Michigan" },
  //   { name: "Minnesota" },
  //   { name: "Mississippi" },
  //   { name: "Missouri" },
  //   { name: "Montana" },
  //   { name: "Nebraska" },
  //   { name: "Nevada" },
  //   { name: "New Hampshire" },
  //   { name: "New Jersey" },
  //   { name: "New Mexico" },
  //   { name: "New York" },
  //   { name: "North Carolina" },
  //   { name: "North Dakota" },
  //   { name: "Ohio" },
  //   { name: "Oklahoma" },
  //   { name: "Oregon" },
  //   { name: "Pennsylvania" },
  //   { name: "Rhode Island" },
  //   { name: "South Carolina" },
  //   { name: "South Dakota" },
  //   { name: "Tennessee" },
  //   { name: "Texas" },
  //   { name: "Utah" },
  //   { name: "Vermont" },
  //   { name: "Virginia" },
  //   { name: "Washington" },
  //   { name: "West Virginia" },
  //   { name: "Wisconsin" },
  //   { name: "Wyoming" }
  // ];

  const format = (value) => {
    const inputPhoneNumber = value?.replace(/\D/g, '');
    const limitedPhoneNumber = inputPhoneNumber?.slice(0, 10);
    const formattedPhoneNumber = limitedPhoneNumber?.length === 10 ? limitedPhoneNumber?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3') : limitedPhoneNumber;
    return formattedPhoneNumber
  }

  const handlePhoneNumberChange = (e) => {
    const inputPhoneNumber = e.target.value
    handleInputChangeNewData({
      target: {
        name: "phoneNumber",
        value: inputPhoneNumber
      },
    })
  };

  return (
    <div className='pb-20'>
      <div className="grid gap-6 grid-cols-2 border-b-2 mb-5
       items-center center">
        <div>
          <div className="flex items-center">
            <div className="flex h-12 w-12 flex-shrink-0
             items-center justify-center rounded-full bg-green-100 ">
              <MdOutlineAdd className="text-green-600 " size={24} />
            </div>
            <p className="text-xl font-medium leading-6 text-gray-900 ml-3"
            >
              {edit ? "Update User" : "Create New User"}
            </p>
          </div>
        </div>
        <div className='items-end flex justify-end font-medium text-blue-500'>
          {orgLocationIdValue}
        </div>
      </div>
      {!address ? <div className="grid gap-6 grid-cols-3">
        <div>
          <div className='flex'>
            <label for="firstName"
              className="block mb-2 text-sm font-medium text-gray-900">First Name
            </label>
            <p className='text-red-500'>*</p>
          </div>
          <input
            type="text"
            id="firstName"
            name='firstName'
            value={jobNewData?.firstName}
            className="bg-gray-50 border border-gray-300
           text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5 "
            placeholder="Enter First Name"
            onChange={e => handleInputChangeNewData(e)}
          />
        </div>
        <div>
          <div className='flex'>
            <label for="lastName"
              className="block mb-2 text-sm font-medium text-gray-900">Last Name
            </label>
            <p className='text-red-500'>*</p>
          </div>
          <input
            type="text"
            id="lastName"
            name='lastName'
            value={jobNewData?.lastName}
            className="bg-gray-50 border border-gray-300
           text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5 
              
              "
            placeholder="Enter Last Name"
            onChange={e => handleInputChangeNewData(e)}
          />
        </div>
        <div>
          <div className='flex'>
            <label for="email"
              className="block mb-2 text-sm font-medium text-gray-900">Email
            </label>
            <p className='text-red-500'>*</p>
          </div>
          <input
            type="email"
            id="email"
            name='email'
            value={jobNewData?.email}
            className="bg-gray-50 border border-gray-300
           text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5"
            placeholder="Enter Email"
            onChange={e => handleInputChangeNewData(e)}
          />
          {jobNewData?.email && !jobNewData?.email?.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) && (
            <p className="text-red-500 text-sm mt-1">Please enter a valid email address.</p>
          )}
        </div>
        <div>
          <label
            for="dateOfBirth"
            className="block mb-2 text-sm font-medium text-gray-900">
            Date Of Birth
          </label>
          <DateOfBirth
            date={jobNewData?.dateOfBirth}
            handleInputChange={handleInputChangeNewData}
            field={"dateOfBirth"} />
        </div>
        <div>
          <div className='flex'>
            <label for="phoneNumber"
              className="block mb-2 text-sm font-medium text-gray-900">Phone Number
            </label>
            <p className='text-red-500'>*</p>
          </div>
          <input
            type="text"
            id="phoneNumber"
            name='phoneNumber'
            value={format(jobNewData?.phoneNumber)}
            className="bg-gray-50 border border-gray-300
           text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5"
            placeholder="Enter Phone Number"
            onChange={e => handlePhoneNumberChange(e)}
            pattern="[0-9]*"
          />
        </div>
        <div>
          <div className='flex'>
            <label
              for="customerVisibility"
              className="block mb-2 text-sm font-medium text-gray-900">
              Gender </label>
            <p className='text-red-500'>*</p>
          </div>
          <div>
            <List
              data={jobNewData?.gender}
              handleInputChangeNewData={handleInputChangeNewData}
              options={gender}
              name="gender" />
          </div>
        </div>
        <div>
          <div className='flex'>
            <label
              for="customerVisibility"
              className="block mb-2 text-sm font-medium text-gray-900">
              Primary LicenseType </label>
            <p className='text-red-500'>*</p>
          </div>
          <div>
            <List
              data={jobNewData?.primaryLicenseType}
              handleInputChangeNewData={handleInputChangeNewData}
              options={option}
              name="primaryLicenseType"
              value={edit?.primaryLicenseType} />
          </div>
        </div>
        {/* <div>
          <div className='flex'>
            <label
              for="customerVisibility"
              className="block mb-2 text-sm font-medium text-gray-900">
              Primary State </label>
            <p className='text-red-500'>*</p>
          </div>
          <div>
            <List
              data={jobNewData}
              handleInputChangeNewData={handleInputChangeNewData}
              options={USAState}
              name="primaryState"
              value={edit?.primaryState} />
          </div>
        </div> */}
        <div className='flex'>
          <div>
            <Checkbox1Presentation
              name={"Primary License MultiPrivilege"}
              label={"Primary License MultiPrivilege"}
              checked={jobNewData?.primaryLicenseMultiPrivilege}
              onChange={(event) => handleInputChangeNewData(
                {
                  "target": {
                    "name": "primaryLicenseMultiPrivilege",
                    "value": event
                  }
                })} />
          </div>
          <div>
          </div>
        </div>
        {/* <div>
          <label
            for="primaryLicenseMultiPrivilege"
            className="block mb-2 text-sm font-medium text-gray-900">
            Primary License MultiPrivilege</label>
          <Switch
            checked={jobNewData?.primaryLicenseMultiPrivilege}
            onChange={(event) => handleInputChangeNewData(
              {
                "target": {
                  "name": "primaryLicenseMultiPrivilege",
                  "value": event
                }
              })}
            className={`${jobNewData?.primaryLicenseMultiPrivilege ? 'bg-green-600' : 'bg-green-900'}
          relative inline-flex h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 
          border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2
            focus-visible:ring-white/75`}
          >
            <span className="sr-only">Use setting</span>
            <span
              aria-hidden="true"
              className={`${jobNewData?.primaryLicenseMultiPrivilege ? 'translate-x-9' : 'translate-x-0'}
            pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full
             bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            />
          </Switch>
        </div> */}
        <div>
          <div className='flex'>
            <label
              for="fullAddress"
              className="block mb-2 text-sm font-medium text-gray-900">
              Full Address</label>
            <p className='text-red-500'>*</p>
          </div>
          <div
            type="text"
            name='fullAddress'
            id="fullAddress"
            value={jobNewData?.fullAddress}
            className="bg-gray-50 
          border border-gray-300 text-gray-900 text-sm rounded-lg
           focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            onClick={() => setAddress(true)} >
            {jobNewData?.fullAddress ? jobNewData?.fullAddress : "Full Address"}
          </div>
        </div>
        <div>
          <div className='flex'>
            <label for="clinicalLicenseNumber"
              className="block mb-2 text-sm font-medium text-gray-900">Clinical License Number
            </label>
          </div>
          <input
            type="text"
            id="clinicalLicenseNumber"
            name='clinicalLicenseNumber'
            value={jobNewData?.clinicalLicenseNumber}
            className="bg-gray-50 border border-gray-300
           text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5 "
            placeholder="Enter Clinical License Number"
            onChange={e => handleInputChangeNewData(e)}
          />
        </div>
        <div>
          <label
            for="clinicalLicenseExpirationDate"
            className="block mb-2 text-sm font-medium text-gray-900">
            Clinical License Expiration Date
          </label>
          <DatePicker1Presentation
            date={jobNewData?.clinicalLicenseExpirationDate}
            handleInputChange={handleInputChangeNewData}
            field={"clinicalLicenseExpirationDate"} />
        </div>
        <div>
          <div className='flex'>
            <label for="password"
              className="block mb-2 text-sm font-medium text-gray-900">Password
            </label>
            <p className='text-red-500'>*</p>
          </div>
          <input
            type="text"
            id="password"
            name='password'
            value={jobNewData?.password}
            className="bg-gray-50 border border-gray-300
           text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5 
              
              "
            placeholder="Enter Password"
            onChange={e => handleInputChangeNewData(e)}
          />
        </div>
        <div className='flex space-x-5'>
          {edit !== undefined ?
            <div className='flex space-x-5'>
              {/* <div>
                <label
                  for="nurseLoginControl"
                  className="block mb-2 text-sm font-medium text-gray-900">
                  DeActivate</label>
                <Switch
                  checked={jobNewData?.nurseLoginControl}
                  onChange={(event) => handleInputChangeNewData(
                    {
                      "target": {
                        "name": "nurseLoginControl",
                        "value": event
                      }
                    })}
                  className={`${jobNewData?.nurseLoginControl ? 'bg-green-600' : 'bg-green-900'}
          relative inline-flex h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 
          border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2
            focus-visible:ring-white/75`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={`${jobNewData?.nurseLoginControl ? 'translate-x-9' : 'translate-x-0'}
            pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full
             bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>
              </div>
              <div>
                <label
                  for="nurseAppAccessControl"
                  className="block mb-2 text-sm font-medium text-gray-900">
                  Suspend</label>
                <Switch
                  checked={jobNewData?.nurseAppAccessControl}
                  onChange={(event) => handleInputChangeNewData(
                    {
                      "target": {
                        "name": "nurseAppAccessControl",
                        "value": event
                      }
                    })}
                  className={`${jobNewData?.nurseAppAccessControl ? 'bg-green-600' : 'bg-green-900'}
          relative inline-flex h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 
          border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2
            focus-visible:ring-white/75`}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={`${jobNewData?.nurseAppAccessControl ? 'translate-x-9' : 'translate-x-0'}
            pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full
             bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
                  />
                </Switch>
              </div> */}
              <div>
                <Checkbox1Presentation
                  name={"DeActivate"}
                  label={"DeActivate"}
                  checked={!jobNewData?.nurseLoginControl}
                  onChange={(event) => handleInputChangeNewData(
                    {
                      "target": {
                        "name": "nurseLoginControl",
                        "value": !event
                      }
                    })} />
              </div>
              {jobNewData?.nurseLoginControl && <div>
                <Checkbox1Presentation
                  name={"Suspend"}
                  label={"Suspend"}
                  checked={jobNewData?.nurseAppAccessControl}
                  onChange={(event) => handleInputChangeNewData(
                    {
                      "target": {
                        "name": "nurseAppAccessControl",
                        "value": event
                      }
                    })} />
              </div>}
            </div>
            :
            //   <div>
            //     <label
            //       for="emailVerified"
            //       className="block mb-2 text-sm font-medium text-gray-900">
            //       OTP Verified</label>
            //     <Switch
            //       checked={jobNewData?.emailVerified}
            //       onChange={(event) => handleInputChangeNewData(
            //         {
            //           "target": {
            //             "name": "emailVerified",
            //             "value": event
            //           }
            //         })}
            //       className={`${jobNewData?.emailVerified ? 'bg-green-600' : 'bg-green-900'}
            // relative inline-flex h-[38px] w-[74px] shrink-0 cursor-pointer rounded-full border-2 
            // border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2
            //   focus-visible:ring-white/75`}
            //     >
            //       <span className="sr-only">Use setting</span>
            //       <span
            //         aria-hidden="true"
            //         className={`${jobNewData?.emailVerified ? 'translate-x-9' : 'translate-x-0'}
            //   pointer-events-none inline-block h-[34px] w-[34px] transform rounded-full
            //    bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
            //       />
            //     </Switch>
            //   </div>
            <div className='flex'>
              <div>
                <Checkbox1Presentation
                  name={"OTP Verification"}
                  label={"OTP Verification"}
                  checked={!jobNewData?.emailVerified}
                  onChange={(event) => handleInputChangeNewData(
                    {
                      "target": {
                        "name": "emailVerified",
                        "value": !event
                      }
                    })} />
              </div>
              <div>
              </div>
            </div>
          }
        </div>
      </div> :
        <AutoFillAddress
          setShowAddress={setAddress}
          handleInputChangeNewData={handleInputChangeNewData}
        />}
      {/* <div className="flex items-start mb-6">
        <div className="flex items-center h-5">
          <input id="remember" type="checkbox" value="" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300   dark:focus:ring-blue-600 dark:ring-offset-gray-800"   />
        </div>
        <label for="remember" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">I agree with the <a href="#" className="text-blue-600 hover:underline dark:text-blue-500">terms and conditions</a>.</label>
      </div> */}
      {/* <button type="submit" className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Submit</button> */}
    </div >
  )
}

export default NurseForm