import { useState } from "react";
import { Switch } from "@headlessui/react";
import { AiOutlineCheck } from "react-icons/ai";

function Checkbox1({ label, name, checked, onChange, disabled }) {
  return (
    <Switch.Group>
      <div className="flex items-center justify-between">
        <Switch.Label className="mr-4">{label}</Switch.Label>
        <Switch
          checked={checked}
          onChange={onChange}
          name={name}
          disabled={disabled}
          className={`
            relative flex h-6 w-6 items-center justify-center rounded-lg transition-all duration-200 outline-none ring-1 
            ${!checked && !disabled ? "ring-gray-400" : ""}
            ${checked && !disabled ? "ring-red-400" : ""} 
            ${disabled ? "bg-gray-200 ring-gray-200" : "bg-white"}  
          `}
        >
          <AiOutlineCheck
            size="1rem"
            className={`
             ${checked ? "scale-100" : "scale-0"} 
             ${checked && !disabled ? "text-red-400" : "text-gray-400"} 
             transition-transform duration-200 ease-out`}
          />
        </Switch>
      </div>
    </Switch.Group>
  );
}

function Checkbox1Presentation({ name, label, checked, onChange }) {
  return (
    <div className="flex flex-col bg-white w-full p-5 sm:p-10 max-w-md gap-8 rounded-md">
      <Checkbox1
        name={name}
        label={label}
        checked={checked}
        onChange={onChange}
      />
    </div>
  );
}

export { Checkbox1Presentation };
