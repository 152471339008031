import { API_URL } from "./config";

export async function getReportAll(authToken) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/report/all`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching");
  }
  return await res.json();
}

export async function getReportDownloadAll(authToken) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/report/job/download`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching");
  }
  return await res.json();
}

export async function getReportDaily(authToken, date) {
  if (!authToken) {
    return;
  }

  const queryParams = new URLSearchParams({
    date: String(date),
  });

  const url = `${API_URL}/report/single/date?${queryParams.toString()}`;

  const res = await fetch(url, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching");
  }
  return await res.json();
}