import React from "react";

export default function TabSelect({ jobNewData, handleInputChangeNewData, type }) {

  return (
    <div>
      <div className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
        <div
          className={`
        w-full rounded-lg py-1.5 text-sm font-medium leading-5 cursor-pointer text-center
        ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2
        ${type === "Shift"
              ? 'text-white bg-blue-700 shadow'
              : 'text-black hover:bg-blue-100 hover:text-gray-700'
            }
        `}
        // onClick={() => {
        //   handleInputChangeNewData(
        //     {
        //       "target": {
        //         "name": "jobType",
        //         "value": "Shift"
        //       }
        //     }
        //   )
        // }}
        >
          Shift
        </div>
        <div
          className={`
        w-full rounded-lg py-1.5 text-sm font-medium leading-5 cursor-pointer text-center
        ring-white/60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2
        ${type === "Visit"
              ? 'text-white bg-blue-700 shadow'
              : 'text-black hover:bg-blue-100 hover:text-gray-700'
            }
        `}
        // onClick={() => {
        //   handleInputChangeNewData(
        //     {
        //       "target": {
        //         "name": "jobType",
        //         "value": "Visit"
        //       }
        //     }
        //   )
        // }}
        >
          Visit
        </div>
      </div>
    </div>
  )
}
