import { API_URL } from "./config";

export async function getFacilityNotification(authToken, userId) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/facility-notification/all_notification/list/${userId}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching");
  }
  return await res.json();
}

export async function getFacilityNotificationDetails(authToken, id) {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/facility-notification/${id}`, {
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-Type": "application/json",
    },
  });
  if (res.status === 401) {
    throw new Error("Not authorized. Please sign in");
  }
  if (res.status !== 200) {
    throw new Error("Error fetching");
  }
  return await res.json();
}

export const postFacilityNotification = async (
  authToken,
  id,
  imageURL,
  title,
  content,
  navigationScreen,
  navigationData,
  visited,
  visitNotification,
  notificationDotTypeColor,
  url,
) => {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/facility-notification`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "Application/json",
    },
    body: JSON.stringify({
      id,
      imageURL,
      title,
      content,
      navigationScreen,
      navigationData,
      visited,
      visitNotification,
      notificationDotTypeColor,
      url,
    }),
  });
  if (res.status === 400) {
    throw new Error("Error during posting.");
  } else {
    return res.json();
  }
};

export const updateFacilityNotification = async (
  authToken, id, data
) => {

  if (!authToken) {
    return;
  }
  const res = await fetch(`${API_URL}/facility-notification/${id}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json", // "application/json" should be lowercase
    },
    body: JSON.stringify(data),
  });

  if (res.status === 400) {
    throw new Error("Network error");
  } else {
    return res.json();
  }
};

export const updateFacilityNotificationListVisit = async (
  authToken,
  ids
) => {

  if (!authToken) {
    return;
  }
  const res = await fetch(`${API_URL}/facility-notification/multi/notification/status/update`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json", // "application/json" should be lowercase
    },
    body: JSON.stringify({
      ids
    }),
  });

  if (res.status === 400) {
    throw new Error("Network error");
  } else {
    return res.json();
  }
};

export const deleteFacilityNotification = async (authToken, ids) => {
  if (!authToken) {
    return;
  }

  const res = await fetch(`${API_URL}/facility-notification/multi/delete`, {
    method: "DELETE",
    headers: {
      Authorization: `Bearer ${authToken}`,
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      ids: ids,
    }),
  });

  //console.log("Response status:", res.status);

  if (res.status === 200) {
    // Handle successful non-JSON response
    const textResult = await res.text();
    //console.log("Non-JSON response:", textResult);
    return textResult;  // You may choose to return or handle textResult as needed
  } else if (res.status === 400) {
    throw new Error("Network error");
  } else {
    try {
      // Assuming JSON response for other status codes
      const jsonResult = await res.json();
      //console.log("JSON response:", jsonResult);
      return jsonResult;
    } catch (error) {
      console.error("Error parsing JSON response:", error);
      throw error;
    }
  }
};