import React from 'react'
import { MdOutlineAdd } from 'react-icons/md'
import AutoFillAddress from './AutoFillAddress'

function LocationForm({
  newLocation,
  handleInputChangeNewData,
  address,
  setAddress
}) {
  return (
    <div className='pb-20'>
      <div className="grid gap-6 grid-cols-2 border-b-2 mb-5
       items-center center">
        <div>
          <div className="flex items-center">
            <div className="flex h-12 w-12 flex-shrink-0
             items-center justify-center rounded-full bg-green-100 ">
              <MdOutlineAdd className="text-green-600 " size={24} />
            </div>
            <p className="text-xl font-medium leading-6 text-gray-900 ml-3"
            >
              Create New Location
            </p>
          </div>
        </div>
      </div>
      {!address ? <div className="grid gap-6 grid-cols-3">
        <div>
          <div className='flex'>
            <label for="location_id"
              className="block mb-2 text-sm font-medium text-gray-900">Location Id
            </label>
            <p className='text-red-500'>*</p>
          </div>
          <input
            type="text"
            id="location_id"
            name='location_id'
            value={newLocation?.location_id}
            className="bg-gray-50 border border-gray-300
           text-gray-900 text-sm rounded-lg focus:ring-blue-500
            focus:border-blue-500 block w-full p-2.5 "
            placeholder="Enter Location Id"
            onChange={e => handleInputChangeNewData(e)}
          />
        </div>
        <div>
          <div className='flex'>
            <label
              for="fullAddress"
              className="block mb-2 text-sm font-medium text-gray-900">
              Full Address</label>
            <p className='text-red-500'>*</p>
          </div>
          <div
            type="text"
            name='location_fullAddress'
            id="location_fullAddress"
            value={newLocation?.location_fullAddress}
            className="bg-gray-50 
          border border-gray-300 text-gray-900 text-sm rounded-lg
           focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            onClick={() => setAddress(true)} >
            {newLocation?.location_fullAddress ? newLocation?.location_fullAddress : "Full Address"}
          </div>
        </div>
      </div> :
        <AutoFillAddress
          setShowAddress={setAddress}
          handleInputChangeNewData={handleInputChangeNewData}
        />}
    </div >
  )
}

export default LocationForm