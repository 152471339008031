import React, { useEffect, useRef, useState } from 'react'

const GOOGLE_PLACES_API_KEY = "AIzaSyCuH8GUhp24JHpPyQeX0N-Alowiiu6e3xM"
const mapApiJs = 'https://maps.googleapis.com/maps/api/js';
const geocodeJson = 'https://maps.googleapis.com/maps/api/geocode/json';

// load google map api js

function loadAsyncScript(src) {
  return new Promise(resolve => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src
    })
    script.addEventListener("load", () => resolve(script));
    document.head.appendChild(script);
  })
}

const extractAddress = (place) => {
  const address = {
    street_number: "",
    route: "",
    locality: "",
    administrative_area_level_3: "",
    administrative_area_level_2: "",
    administrative_area_level_1: "",
    country: "",
    postal_code: "",
    postal_code_suffix: "",
    latitude: "",
    longitude: "",
    plain() {
      const street_number = this.street_number ? this.street_number + ", " : "";
      const route = this.route ? this.route + ", " : "";
      const locality = this.locality ? this.locality + ", " : "";
      const administrative_area_level_3 = this.administrative_area_level_3 ? this.administrative_area_level_3 + ", " : "";
      const administrative_area_level_2 = this.administrative_area_level_2 ? this.administrative_area_level_2 + ", " : "";
      const administrative_area_level_1 = this.administrative_area_level_1 ? this.administrative_area_level_1 + ", " : "";
      const country = this.country ? this.country + ", " : "";
      const postal_code = this.postal_code ? this.postal_code + ", " : "";
      const postal_code_suffix = this.postal_code_suffix ? this.postal_code_suffix + ", " : "";
      return street_number +
        route +
        locality +
        administrative_area_level_3 +
        administrative_area_level_2 +
        administrative_area_level_1 +
        country +
        postal_code +
        postal_code_suffix;
    }
  }

  if (!Array.isArray(place?.address_components)) {
    return address;
  }

  place.address_components.forEach(component => {
    const types = component.types;
    const value = component.long_name;

    if (types.includes("street_number")) {
      address.street_number = value;
    }
    if (types.includes("route")) {
      address.route = value;
    }
    if (types.includes("locality")) {
      address.locality = value;
    }
    if (types.includes("administrative_area_level_3")) {
      address.administrative_area_level_3 = value;
    }
    if (types.includes("administrative_area_level_2")) {
      address.administrative_area_level_2 = value;
    }
    if (types.includes("administrative_area_level_1")) {
      address.administrative_area_level_1 = value;
    }
    if (types.includes("country")) {
      address.country = value;
    }
    if (types.includes("postal_code")) {
      address.postal_code = value;
    }
    if (types.includes("postal_code_suffix")) {
      address.postal_code_suffix = value;
    }

  });

  // console.log(place?.geometry?.viewport)
  // console.log(place?.geometry?.viewport?.Zh)
  // console.log(place?.geometry?.viewport?.Jh)
  // console.log(place?.geometry?.viewport?.Zh?.lo)
  // console.log(place?.geometry?.viewport?.Jh?.lo)

  let points = Object.keys(place?.geometry?.viewport);

  address.latitude = place?.geometry?.viewport?.[points[0]]?.lo;
  address.longitude = place?.geometry?.viewport?.[points[1]]?.lo;

  return address;
}

function AutoFillAddress({ setShowAddress, handleInputChangeNewData }) {

  const searchInput = useRef(null);
  const [address, setAddress] = useState({});

  // init gmap script
  const initMapScript = () => {
    // if script already loaded
    if (window.google) {
      return Promise.resolve();
    }
    const src = `${mapApiJs}?key=${GOOGLE_PLACES_API_KEY}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  }

  // do something on address change
  const onChangeAddress = (autocomplete) => {
    const place = autocomplete.getPlace();
    // setAddress(extractAddress(place));
    // console.log(extractAddress(place))
    handleInputChangeNewData(
      {
        "target": {
          "name": "fullAddress",
          "value": extractAddress(place)?.street_number + ", " +
            extractAddress(place)?.route + ", " +
            extractAddress(place)?.locality + ", " +
            extractAddress(place)?.administrative_area_level_3 + ", " +
            extractAddress(place)?.administrative_area_level_2 + ", " +
            extractAddress(place)?.administrative_area_level_1 + ", " +
            extractAddress(place)?.country + ", " +
            extractAddress(place)?.postal_code + ", " +
            extractAddress(place)?.postal_code_suffix,
        }
      }
    )
    handleInputChangeNewData(
      {
        "target": {
          "name": "latitude",
          "value": extractAddress(place)?.latitude,
        }
      }
    )
    handleInputChangeNewData(
      {
        "target": {
          "name": "longitude",
          "value": extractAddress(place)?.longitude,
        }
      }
    )
    handleInputChangeNewData(
      {
        "target": {
          "name": "city",
          "value": extractAddress(place)?.locality,
        }
      }
    )
    handleInputChangeNewData(
      {
        "target": {
          "name": "state",
          "value": extractAddress(place)?.administrative_area_level_1,
        }
      }
    )
    handleInputChangeNewData(
      {
        "target": {
          "name": "zipCode",
          "value": extractAddress(place)?.postal_code,
        }
      }
    )
    setShowAddress(false);
    // console.log({
    //   fullAddress: extractAddress(place)?.street_number + ", " +
    //     extractAddress(place)?.route + ", " +
    //     extractAddress(place)?.locality + ", " +
    //     extractAddress(place)?.administrative_area_level_3 + ", " +
    //     extractAddress(place)?.administrative_area_level_2 + ", " +
    //     extractAddress(place)?.administrative_area_level_1 + ", " +
    //     extractAddress(place)?.country + ", " +
    //     extractAddress(place)?.postal_code + ", " +
    //     extractAddress(place)?.postal_code_suffix,
    //   latitude: extractAddress(place)?.latitude,
    //   longitude: extractAddress(place)?.longitude,
    // })
  }

  // init autocomplete
  const initAutocomplete = () => {
    if (!searchInput.current) return;

    const autocomplete = new window.google.maps.places.Autocomplete(searchInput.current);
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () => onChangeAddress(autocomplete));
  }

  const reverseGeocode = ({ latitude: lat, longitude: lng }) => {
    const url = `${geocodeJson}?key=${GOOGLE_PLACES_API_KEY}&latlng=${lat},${lng}`;
    searchInput.current.value = "Getting your location...";
    fetch(url)
      .then(response => response.json())
      .then(location => {
        const place = location.results[0];
        const _address = extractAddress(place);
        // setAddress(_address);
        searchInput.current.value = _address.plain();
      })
  }

  const findMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        // reverseGeocode(position.coords)
        reverseGeocode(position.coords)
      })
    }
  }

  // load map script after mounted
  useEffect(() => {
    initMapScript().then(() => initAutocomplete())
  }, []);

  return (
    <>
      <div
        className='flex h-full items-start justify-center box-border'>
        <div className='flex flex-1 flex-col h-full box-border'>
          <div
            onClick={() => setShowAddress(false)}
            className='flex items-end
             justify-end mb-5'
          >
            <div
              onClick={() => setShowAddress(false)}
              className='
            bg-green-600 rounded-lg
            text-white px-6 py-2'

            >Close</div>
          </div>
          <div
            className='flex flex-1 flex-col mb-10'>
            <input
              ref={searchInput}
              type="text"
              placeholder="Search the address here"
              className='flex-1 block text-lg mx-10 py-5 px-3 text-black
              outline-none bg-gray-100'
            />
            {/* <button onClick={findMyLocation}>GpsFixed</button> */}
          </div>
        </div >
      </div >

    </>
  )
}

export default AutoFillAddress;
